import React from 'react';
import Logo from "../assets/logo.png";

function Navbar() {
  return (
    <nav className="fixed top-0 left-0  h-screen flex flex-col justify-between z-50">
        <div className="mt-0">
            <img src={Logo} alt="Block Hunters logo" className="h-7 m-6" />
        </div>

        <div className="flex flex-col items-center space-y-4 w-0 ml-10">
        <a href="https://twitter.com/blockhuntersOrg" className="hover:text-custom-orange cursor-pointer">
            <i className='flaticon flaticon-bh-twitter text-3xl text-white hover:text-custom-orange'></i>
        </a>
        <a href="https://t.me/BlockhuntersAnnouncements" className="hover:text-custom-orange cursor-pointer">
            <i className='flaticon flaticon-bh-telegram text-3xl text-white hover:text-custom-orange'></i>
        </a>
        <a href="https://block-hunters.medium.com" className="hover:text-custom-orange cursor-pointer">
            <i className='flaticon flaticon-bh-medium-ugly text-3xl text-white hover:text-custom-orange'></i>
        </a>
        <a href="https://discord.com" className="hover:text-custom-orange cursor-pointer">
            <i className='flaticon flaticon-bh-discord text-3xl text-white hover:text-custom-orange' ></i>
        </a>
        </div>
        <div></div>
  </nav>
  );
}

export default Navbar;
