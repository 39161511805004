import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

//pages
import Home from './pages/Home/Home';
import BabylonStaking from './pages/BabylonStaking/BabylonStaking';

//layouts
import AppLayout from './layouts/AppLayout';

//styles
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <AppLayout>
            <Home />
          </AppLayout>
        } />
        <Route path="/babylon-staking" element={<BabylonStaking />} />
      </Routes>
    </Router>
  )
}

export default App;